<template>
</template>

<script>
  import { mapState, mapMutations } from "vuex";
  import FingerprintJS from '@fingerprintjs/fingerprintjs';
  export default {
    components:{
      FingerprintJS
    },
    data () {
      return {
        FingerprintOptions: {
          fonts: {
            extendedJsFonts: true,
          },
          excludes: {
            audio: true,
            userAgent: true,
            enumerateDevices: true,
            touchSupport: true,

            hasLiedOs: true,
            hasLiedBrowser: true,
            pixelRatio: true,
            availableScreenResolution: true,
            screenResolution: true,
            plugins: true,

            // canvas: true,
            // webgl: true,
            // fonts: true,
          },
        }
      }
    },
    mounted: function () {
      if (!this.BrowserKey)
        this.HandleFingerprint();
    },
    methods: {
      HandleFingerprint() {
        let $this = this;
        FingerprintJS.get(this.FingerprintOptions, function (Components) {
          var Values = Components.map(function (Component) { return Component.value });
          var Key    = FingerprintJS.x64hash128(Values.join(''), 31);
          $this._setBrowserKey(Key);
        })
      },
      ...mapMutations(['_setBrowserKey'])
    },
    computed: {
      ...mapState(['BrowserKey'])
    }
  };
</script>
